// index.ts

// Styles
import '@/ui/styles/main'

// Icon Sprite
import '@/ui/scripts/icons'

// Images
import '@/ui/images/svg/logo.svg'
import '@/ui/images/svg/logo-small.svg'
import '@/ui/images/png/hero.png'

// Scripts
import 'core-js/modules/es.promise'
import 'core-js/modules/es.array.iterator'
import 'regenerator-runtime/runtime'
import moduleLoader from '@/ui/scripts/module-loader'

// Mock Services
async function mockServer() {
  const { makeServer } = await import('@/api/server')
  makeServer()
}
if (process.env.NODE_ENV === 'development') {
  mockServer()
}

document.addEventListener(
  'DOMContentLoaded',
  () => {
    // ES6 modules loaded dynamically based on DOM query
    const modules: HTMLElement[] = Array.from(document.querySelectorAll('.js-module'))
    moduleLoader(modules)
  },
  false
)
