// icons.ts

// Icon Sprite
import '@/ui/icons/arrow-left.svg'
import '@/ui/icons/arrow-right.svg'
import '@/ui/icons/close-square.svg'
import '@/ui/icons/menu.svg'
import '@/ui/icons/menu--small.svg'
import '@/ui/icons/personal-card.svg'
import '@/ui/icons/receipt-edit.svg'
import '@/ui/icons/search-normal.svg'
import '@/ui/icons/x.svg'

import '@/ui/icons/social-facebook.svg'
import '@/ui/icons/social-instagram.svg'
import '@/ui/icons/social-linkedin.svg'
import '@/ui/icons/social-tiktok.svg'
import '@/ui/icons/social-vimeo.svg'
import '@/ui/icons/social-x.svg'
import '@/ui/icons/social-youtube.svg'
import '@/ui/icons/social-whatsapp.svg'

import '@/ui/icons/icon-construction.svg'
import '@/ui/icons/icon-defence.svg'
import '@/ui/icons/icon-gov.svg'
import '@/ui/icons/icon-logistics.svg'
import '@/ui/icons/icon-recycle.svg'
import '@/ui/icons/icon-wheat.svg'
import '@/ui/icons/arrow-right.svg'
import '@/ui/icons/breadcrumb-home.svg'
import '@/ui/icons/bag.svg'
import '@/ui/icons/book.svg'
import '@/ui/icons/clipboard-text.svg'
import '@/ui/icons/four-squares.svg'
import '@/ui/icons/compare.svg'
import '@/ui/icons/filter.svg'
import '@/ui/icons/bag-tick.svg'
import '@/ui/icons/tag-2.svg'
import '@/ui/icons/phone.svg'
import '@/ui/icons/map-pin.svg'
import '@/ui/icons/globe.svg'
import '@/ui/icons/globe-03.svg'
import '@/ui/icons/shopping-cart.svg'
import '@/ui/icons/trash.svg'
import '@/ui/icons/map.svg'
import '@/ui/icons/email.svg'
import '@/ui/icons/award.svg'
import '@/ui/icons/moon.svg'
import '@/ui/icons/sun.svg'
import '@/ui/icons/close-circle.svg'
